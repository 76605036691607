exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-o-nas-proces-redakcyjny-js": () => import("./../../../src/pages/o-nas/proces-redakcyjny.js" /* webpackChunkName: "component---src-pages-o-nas-proces-redakcyjny-js" */),
  "component---src-pages-o-nas-redakcja-js": () => import("./../../../src/pages/o-nas/redakcja.js" /* webpackChunkName: "component---src-pages-o-nas-redakcja-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-author-page-author-page-js": () => import("./../../../src/templates/AuthorPage/AuthorPage.js" /* webpackChunkName: "component---src-templates-author-page-author-page-js" */),
  "component---src-templates-blog-list-blog-list-js": () => import("./../../../src/templates/BlogList/BlogList.js" /* webpackChunkName: "component---src-templates-blog-list-blog-list-js" */),
  "component---src-templates-blog-post-blog-post-js": () => import("./../../../src/templates/BlogPost/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-blog-post-js" */),
  "component---src-templates-iframe-widgets-iframe-widgets-js": () => import("./../../../src/templates/IframeWidgets/IframeWidgets.js" /* webpackChunkName: "component---src-templates-iframe-widgets-iframe-widgets-js" */)
}

